



import { Component, Vue, Emit } from 'vue-property-decorator';
import VueRouter from 'vue-router';
import store from '../../../store';
import { PutAccountUpdateRequest, MailaddressReminder } from '../../../../types/typescript-axios/api';
import axios, {AxiosResponse, AxiosError} from 'axios';
import StepNavi from '../../../components/contents/stepNavi/StepNavi.vue';
import { load } from 'recaptcha-v3';
import auth from '../../../store/auth';

@Component({
  components: {
    StepNavi,
  },
})
export default class AccountEditAcountConfirm extends Vue {

  private get stepNavi(): string {
    const $parent: any = this.$parent;
    return $parent.stepNavi;
  }

  private get mail(): string {
    const $parent: any = this.$parent;
    return $parent.mail;
  }
  private get name1(): string {
    const $parent: any = this.$parent;
    return $parent.name1;
  }
  private get name2(): string {
    const $parent: any = this.$parent;
    return $parent.name2;
  }
  private get birthYear(): string {
    const $parent: any = this.$parent;
    return $parent.birthYear;
  }
  private get companyName(): string {
    const $parent: any = this.$parent;
    return $parent.companyName;
  }
  private get departmentName(): string {
    const $parent: any = this.$parent;
    return $parent.departmentName;
  }
  private get sex(): number {
    const $parent: any = this.$parent;
    return $parent.sex;
  }
  private get consent(): boolean {
    const $parent: any = this.$parent;
    return $parent.consent;
  }
  private get password(): string {
    const $parent: any = this.$parent;
    return $parent.password;
  }

  private get newPassword(): string {
    const $parent: any = this.$parent;
    return $parent.newPassword;
  }

  private get mailReInput(): string {
    const $parent: any = this.$parent;
    return $parent.mailReInput;
  }
  private get passwordReInput(): string {
    const $parent: any = this.$parent;
    return $parent.passwordReInput;
  }
  private get secretQuestAans(): string {
    const $parent: any = this.$parent;
    return $parent.secretQuestAans;
  }
  private get tel1(): string {
    const $parent: any = this.$parent;
    return $parent.tel1;
  }
  private get tel2(): string {
    const $parent: any = this.$parent;
    return $parent.tel2;
  }
  private get tel3(): string {
    const $parent: any = this.$parent;
    return $parent.tel3;
  }
  private get beforeMail(): string {
    const $parent: any = this.$parent;
    return $parent.beforeMail;
  }
  @Emit()
  private backpage(): void {
    window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
  }

  private async updateMember() {
    const params: PutAccountUpdateRequest = {
      sei: this.name1,
      mei: this.name2,
      birth_year: this.birthYear,
      sex: this.sex,
      comp_nm: this.companyName,
      div_nm: this.departmentName,
      email: this.beforeMail.replace(/\r?\n/g, ''),
      tel1: this.tel1,
      tel2: this.tel2,
      tel3: this.tel3,
      upassword: this.password,
      secret_quest_ans: this.secretQuestAans,
      exist_pic: this.consent,
    };
    if (this.newPassword) {
      params.upassword_new = this.newPassword;
    }

    const $parent: any = this.$parent;
    try {
      await store.dispatch('account/updateMember', params);
    } catch (error) {
      $parent.setStatus(500);
      $parent.setErrorCode(error.response.data.errors.code);
    }
    if ($parent.status === 200) {
      this.$router.replace('/account/edit_account/complete/');
    } else {
      this.$router.replace('/account/edit_account/error/');
    }
  }
  get accountEditAccountUrl(): string {
    return process.env.VUE_APP_PATH_ACCOUNT_EDIT_ACCOUNT;
  }
}
